var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "displayPoduct" },
    [
      _vm.DemandNo
        ? _c("div", [_vm._v("需求订单:" + _vm._s(_vm.DemandNo))])
        : _vm._e(),
      _c("div", { staticClass: "product-title" }, [
        _vm._v(" " + _vm._s(_vm.mainProduct.taxonomyName) + " ")
      ]),
      !_vm.refresh
        ? _c("classification", {
            attrs: { title: "分类", dataList: _vm.classList },
            on: { change: _vm.changeClass }
          })
        : _vm._e(),
      !_vm.refresh && !_vm.refreshChild && _vm.childList.length > 0
        ? _c(
            "div",
            { staticClass: "childList" },
            [
              _c("classification", {
                attrs: { dataList: _vm.childList, full: false, title: "类型" },
                on: { change: _vm.selectChildClass }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "product-area" }, [
        _c(
          "ul",
          { staticClass: "product-list" },
          _vm._l(_vm.productList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "product-list-item",
                on: {
                  click: function($event) {
                    return _vm.PoductDetail(item)
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: "http://" + item.describe.picture[0] }
                }),
                _c("div", { staticClass: "proName" }, [
                  _vm._v(_vm._s(item.spuName))
                ])
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticStyle: { margin: "20px 0px 20px 20px" },
          attrs: { align: "right" }
        },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }