var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Classification" }, [
    _vm.title
      ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title) + ":")])
      : _vm._e(),
    !_vm.title
      ? _c("span", { staticClass: "title hidden" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c(
      "ul",
      { ref: "classList", class: [{ hidden: _vm.hidden }, "class-list"] },
      [
        _vm.title && _vm.full
          ? _c(
              "li",
              {
                staticClass: "class-item",
                class: { active: _vm.current == -1 },
                on: {
                  click: function($event) {
                    return _vm.change(null, -1)
                  }
                }
              },
              [_vm._v(" 全部 ")]
            )
          : _vm._e(),
        _vm._l(_vm.dataList, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "class-item",
              class: { active: _vm.current == index },
              on: {
                "": function($event) {},
                click: function($event) {
                  return _vm.change(item, index)
                }
              }
            },
            [_vm._v(" " + _vm._s(item.taxonomyName) + " ")]
          )
        })
      ],
      2
    ),
    _vm.showMore
      ? _c(
          "div",
          {
            staticClass: "more-button",
            on: {
              click: function($event) {
                _vm.hidden = !_vm.hidden
              }
            }
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hidden,
                    expression: "hidden"
                  }
                ]
              },
              [_vm._v("更多"), _c("i", { staticClass: "el-icon-arrow-down" })]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hidden,
                    expression: "!hidden"
                  }
                ]
              },
              [_vm._v("收起"), _c("i", { staticClass: "el-icon-arrow-up" })]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }