var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadFile" },
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            action: "",
            "show-file-list": false,
            "on-success": _vm.uploadAwardSuccess,
            "before-upload": _vm.awardBeforeUpload,
            "on-progress": _vm.getSing
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }