<template>
  <div class="take-order-area">
    <div class="title">帮我下单</div>
    <div class="step-list">
      <ul class="step-ul">
        <li class="step-item">提交下单需求</li>
        <li class="step-item">工作人员处理文件</li>
        <li class="step-item">确认工艺参数</li>
        <li class="step-item">下单支付</li>
        <li class="step-item">确定生产</li>
        <li class="step-item">等待收货</li>
      </ul>
    </div>
    <div class="user-area">
      <div class="row">
        <div class="name required">货品名称</div>
        <div class="input-large">
          <el-input
            v-model="goodsName"
            maxlength="100"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="name required">联系人</div>
        <div class="input">
          <el-input v-model="founder" maxlength="20" show-word-limit></el-input>
        </div>
        <div class="name required">手机号码</div>
        <div class="input">
          <el-input v-Int v-model="founderPhone"></el-input>
        </div>
        <div class="name">QQ号码</div>
        <div class="input">
          <el-input
            v-Int
            v-model="qqNumber"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
      </div>
    </div>
    <div class="remark-area">
      <div class="row">
        <div class="name">需求描述</div>
        <div class="input">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="describe"
            show-word-limit
            maxlength="500"
            resize="none"
            :rows="5"
          >
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="name">文件</div>
        <div class="input">
          <div class="upload-area">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :before-upload="awardBeforeUpload"
              :on-success="uploadAwardSuccess"
              :on-remove="onRemove"
              :on-progress="onProgress"
              :limit="1"
              :file-list="fileList"
            >
              <div class="upload">{{ tips }}</div>
              <div slot="tip" class="el-upload__tip">文件大小不超过100M</div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="name">备注</div>
        <div class="input">
          <el-input v-model="remark" show-word-limit maxlength="100"></el-input>
        </div>
      </div>
    </div>
    <div class="submit-area">
      <div class="submit-button" @click="submit">立即提交</div>
    </div>
  </div>
</template>

<script>
import uploadFile from '@/components/upload/uploadFile.vue'
import { demandOrderSave } from '@/api/printonline'
import { orderdemandsubmit } from '@/api/cubeclient'

import { mapMutations } from 'vuex'
import Tool from '@/lib/tool.js'
export default {
  data () {
    return {
      goodsName: '',
      founder: '',
      founderPhone: '',
      qqNumber: '',
      describe: '',
      remark: '',
      designDocuments: '',
      fileName: '',
      fileList: [],
      uploadUrl: '',
      tips: '上传自带文件'
    }
  },
  components: {
    uploadFile
  },
  created () {
    this.uploadUrl = process.env.VUE_APP_URL + `/cube_web_api/sys/oss/upload?token=${localStorage.getItem('token')}`
  },
  methods: {
    onProgress () {
      this.tips = '文件上传中...'
    },
    awardBeforeUpload (file) {
      const isXlsx = file.name.endsWith('xlsx')
      const isXls = file.name.endsWith('xls')
      const isPSD = file.name.endsWith('psd')
      const isTxt = file.type === 'text/plain'
      const isPdf = file.type === 'application/pdf'
      const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const isDoc = file.type === 'application/msword'
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      if (!isJPG && !isPNG && !isDoc && !isDocx && !isPdf && !isTxt && !isPSD && !isXls && !isXlsx) {
        this.$message.error('上传图片只能是doc,docx,psd,pdf,txt,png,jpg,xlsx,xls格式!')
        return false
      }
      if (localStorage.getItem('token') == null) {
        this.$Login()
        return false
      }
      const limit = file.size / 1024 / 1024 < 100
      if (!limit) {
        this.$message.error('文件大小不能超过100M')
        return false
      }
      return true
    },
    uploadAwardSuccess (res, file) {
      this.tips = '上传自带文件'
      if (res.code == 401) {
        this.$Login()
        return
      }
      this.designDocuments = res.data
    },
    onRemove (file) {
      this.tips = '上传自带文件'
      this.designDocuments = ''
    },
    addFile (data) {
      this.designDocuments = data.fileUrl
      this.fileName = data.fileName
    },
    async submit () {
      if (this.tips.startsWith("文件上传中")) {
        this.$message({
          message: '文件正在上传',
          type: 'warning'
        })
        return
      }
      if (this.goodsName == '') {
        this.$message({
          message: '请填写货品名称',
          type: 'warning'
        })
        return
      }
      if (this.founder == '') {
        this.$message({
          message: '请填写联系人',
          type: 'warning'
        })
        return
      }

      if (!Tool.isPhoneNumber(this.founderPhone)) {
        this.$message({
          message: '请填写正确的手机号码',
          type: 'warning'
        })
        return
      }

      const res = await orderdemandsubmit({
        contactsPerson: this.founder,
        contactsPersonPhone: this.founderPhone,
        describe: this.describe,
        designDocuments: this.designDocuments,
        productName: this.goodsName,
        qqNumber: this.qqNumber,
        remark: this.remark
      })
      if (res.code == 200) {
        this.$router.push({ name: 'DemandOrder', params: { orderNumber: res.data } })
      } else {
        this.$message.error(res.msg)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.required {
  &::before {
    content: "*";
    color: rgb(255, 0, 0);
  }
}
.take-order-area {
  position: absolute;
  .title {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #191c3d;
  }
  .step-list {
    height: 120px;
    display: flex;
    align-items: center;
    .step-ul {
      list-style-type: none;
      .step-item {
        float: left;
        height: 60px;
        width: 172px;
        line-height: 60px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        background: url("~@assets/img/step-two.png");
        &:nth-child(1) {
          background: url("~@assets/img/step.png");
          width: 159px;
        }
      }
    }
  }

  .user-area {
    width: 1032px;
    height: 151px;
    background: #f8f8f8;
    padding-top: 17px;
    .row {
      display: inline-block;
      margin-bottom: 20px;
      .name {
        float: left;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 132px;
        line-height: 40px;
        padding-right: 10px;
      }
      .input {
        float: left;
        width: 200px;
      }
      .input-large {
        float: left;
        width: 866px;
      }
    }
  }

  .remark-area {
    width: 1032px;
    // height: 312px;
    padding-top: 37px;
    margin-top: 5px;
    background: #f2f2f2;
    .row {
      display: inline-block;
      margin-bottom: 20px;
      width: 100%;
      .input {
        float: left;
        width: 866px;
        .upload-area {
          // width: 106px;

          float: left;
          background: #f5f5f5;
          text-align: center;
          // line-height: 36px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          // overflow: hidden;
        }
        .upload-demo {
          width: 300px;
          float: left;
          text-align: left;
        }
        .upload {
          float: left;
          width: 106px;
          height: 36px;
          background: #f5f5f5;
          border: 1px solid #cccccc;
          text-align: center;
          line-height: 36px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          // overflow: hidden;
        }
      }
      .name {
        float: left;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 132px;
        line-height: 40px;
        padding-right: 10px;
      }
    }
  }
}

.submit-area {
  position: relative;
  .submit-button {
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    width: 300px;
    height: 60px;
    background: #2468f2;
    border-radius: 4px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #f5f5f5;
  }
}
</style>
