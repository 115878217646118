<template>
  <div class="displayPoduct">
    <classification
      v-if="!refresh"
      title="分类"
      :dataList="classList"
      @change="changeClass"
    ></classification>
    <div class="product-area">
      <div
        class="product-list"
        v-for="(itemList, index) in imgShow"
        :key="index"
      >
        <div
          class="product-list-item"
          v-for="(item, index) in itemList"
          :key="index"
          @click="PoductDetail(item)"
        >
          <img :src="`http://${item.describe.picture[0]}`" />
          <div class="name" align="center">{{ item.spuName }}</div>
          <!-- {{item}} -->
        </div>
      </div>
    </div>
    <div align="right" style="margin: 20px 0px 20px 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Classification from '../components/Classification.vue'
import { selectAllTaxonomy, queryPage } from '@/api/printonline'
import { getRoleIds } from '@/lib/util'
export default {
  data () {
    return {
      classList: [
      ],
      mainProduct: {},
      id: undefined,
      productList: [],
      page: 1,
      limit: 10,
      taxonomy: '',
      DemandNo: undefined,
      refresh: false,
      total: 0,
      searchContent: '',
      imgShow: [
        [],
        [],
        [],
        [],
        [],
        []
      ],
      columnHeight: [0, 0, 0, 0, 0, 0]
    }
  },
  components: {
    Classification
  },
  created () {
    const userInfo = getRoleIds()
    const Operators = userInfo.roleIds ? userInfo.roleIds.includes(3) : undefined
    const DemandOprder = sessionStorage.getItem('DemandOprder')
    this.searchContent = this.$route.params.searchPrint
    if (Operators && DemandOprder == null) {
      this.$router.push({ path: '/' })
    }
    this.$bus.$on('searchPrint', (searchContent) => {
      this.searchContent = searchContent
      this.init()
    })
    // if(Operators){
    //   this.DemandNo = JSON.parse(DemandOprder).demandNo
    // }
  },
  beforeDestroy () {
    this.$bus.$off('searchPrint')
  },
  mounted () {
    this.init()
  },
  methods: {
    initWaterfall () {
      this.imgShow = [
        [],
        [],
        [],
        [],
        [],
        []
      ]
      this.columnHeight = [0, 0, 0, 0, 0, 0]
    },
    handleCurrentChange (val) {
      this.page = val
      this.searchProduct()
    },
    handleSizeChange (val) {
      this.limit = val
      this.page = 1
      this.searchProduct()
    },
    changeClass (item) {
      this.page = 1
      console.log(item)
      if (item == null) {
        this.taxonomy = []
      } else {
        let result = []
        if (item.childList == null) {
          item.childList = []
        }
        item.childList.forEach(e => {
          result.push(e.id)
          const child = e.childList ? e.childList : []
          result = [...result, ...child.map(item => item.id)]
        })
        this.taxonomy = result
        this.taxonomy.push(item.id)
      }
      //  this.taxonomy = item.id
      this.searchProduct()
    },
    async init () {
      this.initWaterfall()
      this.page = 1
      this.limit = 20
      this.refresh = true
      this.$nextTick(() => {
        this.refresh = false
      })
      this.id = 39
      // this.taxonomy=[39]
      const res = await selectAllTaxonomy({ id: this.id })// 查找标签
      this.taxonomy = []
      this.classList = res.data[0].childList
      if (this.classList == null) {
        this.productList = []
      } else {
        //  this.taxonomy =this.classList[0].id
        this.searchProduct()
      }
    },
    async searchProduct () {
      this.initWaterfall()
      const taxonomy = this.taxonomy
      const spuRes = await queryPage({ classifications: [...taxonomy], pageNo: this.page, pageSize: this.limit, spuName: this.searchContent })// 搜索商品
      //  let spuRes = await listSpuForWeb({offset:0,limit:20})
      const imgList = spuRes.data.list
      this.total = spuRes.data.total
      imgList.forEach(element => {
        element.describe = eval(`(${element.describe})`)
      })
      /**
           * 瀑布流操作
           */
      const loadImage = () => {
        if (imgList.length == 0) {
          return
        }
        const imgItem = imgList.pop()
        const img = new Image()
        if (!imgItem.describe.picture) {
          imgItem.describe.picture = []
          img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAPklEQVR4nO3BMQEAAADCoPVPbQsvoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgKcBnKQAAaZ1lY4AAAAASUVORK5CYII='
        } else {
          img.src = 'https://' + imgItem.describe.picture[0]
        }

        const columnHeight = this.columnHeight

        const imgShow = this.imgShow
        img.onload = () => {
          let minIndex = 0
          let minValue = columnHeight[0]
          columnHeight.forEach((e, index) => {
            if (e < minValue) {
              minValue = e
              minIndex = index
            }
          })
          imgShow[minIndex].push(imgItem)
          columnHeight[minIndex] += img.naturalHeight / img.naturalWidth * 230
          if (imgList.length != 0) {
            loadImage()
          }
        }
      }
      loadImage()
    },
    PoductDetail (prod) {
      const { href } = this.$router.resolve({ name: 'PoductDetail', query: { spuId: prod.id, taxonomy: this.taxonomy, DemandNo: this.DemandNo } })
      window.open(href, '_blank')
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        this.init()
        // console.log('123')
      },
      // 深度观察监听
      deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
.displayPoduct {
  width: 100%;
}
.product-title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #191c3d;
  margin-bottom: 30px;
}
.product-area {
  width: 100%;
  margin-top: 30px;
  display: inline-block;
  .product-list {
    width: 15%;
    margin-left: 20px;
    float: left;
    display: inline-block;
    list-style-type: none;
    .product-list-item {
      cursor: pointer;
      float: left;
      display: inline-block;
      width: 100%;
      // height: 305px;
      margin-right: 22px;
      margin-bottom: 50px;
      transition: 0.2s;
      box-shadow: 0px 0px 1px rgb(102, 102, 102);
      .name {
        overflow: hidden;
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
        padding-bottom: 20px;
        background-color: white;
      }

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 10px rgb(102, 102, 102);
      }
      img {
        width: 100%;
        // height: 100%;
      }
    }
  }
}
</style>
