<template>
  <div class="Classification">
    <span class="title" v-if="title">{{ title }}:</span>
    <span class="title hidden" v-if="!title">{{ title }}</span>
    <ul :class="[{ hidden: hidden }, 'class-list']" ref="classList">
      <li
        v-if="title && full"
        class="class-item"
        @click="change(null, -1)"
        :class="{ active: current == -1 }"
      >
        全部
      </li>
      <!-- <li v-if="!title" class='class-item hidden'>全部</li> -->
      <li
        class="class-item"
        v-for="(item, index) in dataList"
        @
        :key="index"
        @click="change(item, index)"
        :class="{ active: current == index }"
      >
        {{ item.taxonomyName }}
      </li>
    </ul>
    <div class="more-button" v-if="showMore" @click="hidden = !hidden">
      <span v-show="hidden">更多<i class="el-icon-arrow-down"></i></span>
      <span v-show="!hidden">收起<i class="el-icon-arrow-up"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: []
    },
    full: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      current: -1,
      showMore: false,
      hidden: true
    }
  },
  mounted () {
    if (this.$refs.classList.scrollHeight > 33) {
      this.showMore = true
    }
  },
  methods: {
    change (item, index) {
      this.current = index
      this.$emit('change', item)
    }

  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  color: rgba(0, 0, 0, 0);
}
.more-button {
  font-size: 14px;
  width: 100px;
  line-height: 30px;
  color: #888888;
}
.hidden {
  overflow: hidden;
  height: 33px;
}
.Classification {
  display: flex;
  margin-bottom: 5px;
  // align-items: center;
  .title {
    flex-shrink: 0;
    width: 80px;
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 30px;
  }
  .class-list {
    list-style-type: none;
    float: left;
    padding: 0;
    margin: 0;

    // overflow: hidden;
    .class-item {
      float: left;
      margin-right: 30px;
      margin-bottom: 5px;
      // background-color: #f7f8ff;
      padding: 5px;
      border-radius: 2px;
      height: 28px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444950;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
    }
    .active {
      color: #3c74f1ff;
      background-color: #ebf1feff;
    }
  }
}
</style>
