<template>
  <div class="uploadFile">
    <el-upload
      class="avatar-uploader"
      action=""
      :show-file-list="false"
      :on-success="uploadAwardSuccess"
      :before-upload="awardBeforeUpload"
      :on-progress="getSing"
    >
      <slot></slot>
      <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
    </el-upload>
  </div>
</template>

<script>
import COS from 'cos-js-sdk-v5'
import { getTmpCredential } from '@/api/myDesign'
export default {
  props: ['showImg'],
  data () {
    return {
      uploadFile: '',
      uploadFileName: '',
      Sign: {},
      uuid: '',
      url: '',
      format: '',
      uploading: false
    }
  },
  props: {
    img: {
      type: Boolean,
      default: false
    },
    pdf: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    uploadAwardSuccess (res, file) {
    },
    awardBeforeUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isPdf = file.type === 'application/pdf'
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message.error('文件大小不能超过50M')
        return false
      }
      if (!isJPG && !isPNG && this.img) {
        this.$message.error('只能上传是 JPG、PNG 格式!')
        return false
      }
      if (!isPdf && this.pdf) {
        this.$message.error('只能上传PDF格式!')
        return false
      }
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.uploadFile = file
      this.uploadFileName = file.name
      this.format = file.name.split('.')[file.name.split('.').length - 1]
      this.uploading = true
      return true
    },
    getSing () {
      getTmpCredential({
        token: localStorage.getItem('token')
      }).then(res => {
        if (res.code === 0) {
          this.Sign = res.data
          this.upload()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    getUuid (uuid, isWeb) {
      const idArr = []
      const hexDigits = '0123456789abcdef'
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      if (isWeb) {
        return uuid = idArr.join('')
      } else {
        return uuid = 'web_' + idArr.join('')
      }
    },
    async upload () {
      var that = this
      var cos = new COS({
        getAuthorization: function (options, callback) {
          callback({
            TmpSecretId: that.Sign.credentials.tmpSecretId,
            TmpSecretKey: that.Sign.credentials.tmpSecretKey,
            XCosSecurityToken: that.Sign.credentials.sessionToken,
            StartTime: that.Sign.startTime,
            ExpiredTime: that.Sign.expiredTime,
            expiration: that.Sign.expiration,
            requestId: that.Sign.requestId
          })
        }
      })
      const uploadFileName = this.getUuid(this.uploadFileName)

      cos.putObject({
        Bucket: process.env.VUE_APP_BUCKET,
        Region: 'ap-guangzhou',
        // Key: '/upload/' + uploadFileName,
        Key: '/upload/' + uploadFileName + '.' + this.format,
        StorageClass: 'STANDARD',
        Body: that.uploadFile, // 上传文件对象
        onProgress: function (progressData) {
        }
      },
        (err, data) => {
          this.loading.close()
          // console.log('data', data)
          if (data && data.statusCode === 200) {
            let obj = {
              fileUrl: 'https://' + data.Location,
              fileName: that.uploadFileName.split('.')[0]
            }
            if (that.img) {
              obj = {
                ...obj,
                ...{
                  imgUrl: 'https://' + data.Location,
                  imgName: that.uploadFileName.split('.')[0],
                  title: that.uploadFileName.split('.')[0]
                }
              }
            }
            if (this.uploading) {
              that.$emit('addFile', obj)
              this.uploading = false
            }

            // console.log(that)
          }
        })
    }
  }
}

</script>

<style scoped  lang="scss">
/deep/ .avatar-uploader {
  height: 100%;
}
/deep/ .avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}
/*.avatar-uploader .el-upload:hover {*/
/*border-color: #409eff;*/
/*}*/
/deep/ .avatar-uploader-icon {
  font-size: 30px;
  color: #8c939d;
  width: 160px !important;
  height: 200px !important;
  line-height: 200px;
  text-align: center;
  background: #f2f2f2;
}
.uploadImg {
  width: 160px;
  height: 200px;
  vertical-align: middle;
}
.avatar {
  width: 160px;
  height: 200px;
  display: block;
}
</style>
