<template>
  <div class="displayPoduct">
    <div v-if="DemandNo">需求订单:{{ DemandNo }}</div>
    <div class="product-title">
      {{ mainProduct.taxonomyName }}
    </div>
    <classification
      v-if="!refresh"
      title="分类"
      :dataList="classList"
      @change="changeClass"
    ></classification>
    <div
      v-if="!refresh && !refreshChild && childList.length > 0"
      class="childList"
    >
      <classification
        :dataList="childList"
        @change="selectChildClass"
        :full="false"
        title="类型"
      ></classification>
    </div>

    <div class="product-area">
      <ul class="product-list">
        <li
          class="product-list-item"
          v-for="(item, index) in productList"
          :key="index"
          @click="PoductDetail(item)"
        >
          <img :src="`http://${item.describe.picture[0]}`" />
          <div class="proName">{{ item.spuName }}</div>
          <!-- {{item}} -->
        </li>
      </ul>
    </div>
    <div align="right" style="margin: 20px 0px 20px 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Classification from '../components/Classification.vue'
import { selectAllTaxonomy, queryPage } from '@/api/printonline'
import { getRoleIds } from '@/lib/util'
export default {
  data () {
    return {
      classList: [

      ],
      mainProduct: {},
      id: undefined,
      productList: [],
      page: 1,
      limit: 10,
      taxonomy: [],
      DemandNo: undefined,
      refresh: false,
      total: 0,
      childIdList: [],
      childList: [],
      refreshChild: false
    }
  },
  components: {
    Classification
  },
  created () {
    let Operators
    const userInfo = getRoleIds()
    if (localStorage.getItem('token')) {
      Operators = userInfo.roleIds.includes(3)
    }

    const DemandOprder = sessionStorage.getItem('DemandOprder')
    if (Operators && DemandOprder == null) {
      this.$router.push({ path: '/' })
    }
    if (Operators) {
      this.DemandNo = JSON.parse(DemandOprder).demandNo
    }
  },
  mounted () {
    console.log(this.$route)
    this.id = this.$route.params.id

    this.init()
  },
  methods: {
    handleCurrentChange (val) {
      this.page = val
      this.searchProduct()
    },
    handleSizeChange (val) {
      this.limit = val
      this.searchProduct()
    },
    changeClass (item) {
      this.childList = []
      this.childIdList = []
      if (item == null) {
        let result = []
        this.classList.forEach(e => {
          result.push(e.id)
          const child = e.childList ? e.childList : []
          result = [...result, ...child.map(item => item.id)]
        })
        this.taxonomy = result
      } else {
        // console.log(item)
        this.childList = item.childList ? item.childList : []
        this.childIdList = this.childList.map(e => {
          return e.id
        })
        this.taxonomy = [item.id, ...this.childIdList]
      }
      if (this.taxonomy.length == 0) {
        this.taxonomy.push(this.id)
      }
      this.refreshChild = true
      this.$nextTick(() => {
        this.refreshChild = false
      })

      this.searchProduct()
    },
    selectChildClass (item) {
      this.taxonomy = [item.id, item.parentTaxonomyId]
      if (this.taxonomy.length == 0) {
        this.taxonomy.push(this.id)
      }
      this.searchProduct()
    },
    async init () {
      this.page = 1
      this.limit = 20
      this.childList = []
      this.refresh = true
      this.refreshChild = true

      this.$nextTick(() => {
        this.refresh = false
        this.refreshChild = false
      })
      this.id = this.$route.params.id
      const res = await selectAllTaxonomy({ id: this.id })// 查找标签

      this.mainProduct = res.data[0]
      this.classList = res.data[0].childList
      if (this.classList == null) {
        this.productList = []
      } else {
        let result = []
        this.classList.forEach(e => {
          result.push(e.id)
          const child = e.childList ? e.childList : []
          result = [...result, ...child.map(item => item.id)]
        })
        this.taxonomy = result
        if (this.taxonomy.length == 0) {
          this.taxonomy.push(this.id)
        }
        this.searchProduct()
      }
    },
    async searchProduct () {
      const taxonomy = this.taxonomy
      const spuRes = await queryPage({ classifications: [...taxonomy], pageNo: this.page, pageSize: this.limit })// 搜索商品
      this.productList = spuRes.data.list
      this.total = spuRes.data.total
      this.productList.forEach(element => {
        element.describe = eval(`(${element.describe})`)
      })
    },
    PoductDetail (prod) {
      const { href } = this.$router.resolve({ name: 'PoductDetail', query: { spuId: prod.id, taxonomy: [this.$route.params.id, ...this.taxonomy], DemandNo: this.DemandNo, taxonomyName: this.mainProduct.taxonomyName } })
      window.open(href, '_blank')
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        this.init()
        // console.log('123')
      },
      // 深度观察监听
      deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
.displayPoduct {
  width: 100%;
}
.product-title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #191c3d;
  margin-bottom: 30px;
}
.product-area {
  width: 100%;
  margin-top: 30px;
  display: inline-block;
  .product-list {
    list-style-type: none;
    .product-list-item {
      cursor: pointer;
      float: left;
      display: inline-block;
      width: 224px;
      // height: 305px;
      padding-bottom: 5px;
      margin-right: 22px;
      margin-bottom: 30px;
      transition: 0.2s;
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 10px rgb(102, 102, 102);
      }
      img {
        width: 100%;
        height: 100%;
        vertical-align: bottom;
      }
      .proName {
        height: 17px;
        padding-bottom: 25px;
        font-size: 16px;
        margin-top: 5px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #33383e;
        text-align: center;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
      }
    }
  }
}
</style>
