var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "aside" }, [
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _vm._l(_vm.meunList, function(item, index) {
            return [
              _c(
                "router-link",
                {
                  key: index,
                  attrs: {
                    to: "/OnlinePrinting/DisplayPoduct/" + item.id,
                    tag: "li"
                  }
                },
                [
                  _c("div", { staticClass: "item-content" }, [
                    _c("div", { staticClass: "pic" }, [
                      _c("img", {
                        staticClass: "image",
                        attrs: { src: item.taxonomyIcon }
                      })
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(item.taxonomyName) + " ")
                    ])
                  ])
                ]
              )
            ]
          }),
          _c(
            "router-link",
            { attrs: { to: "/OnlinePrinting/TakeOrder", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: { src: require("@/assets/img/shoppingcart.png") }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("帮我下单")])
              ])
            ]
          )
        ],
        2
      )
    ]),
    _c("div", { staticClass: "main" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }