<template>
  <div class="content">
    <div class="aside">
      <ul class="nev-menu-ul">
        <template v-for="(item, index) in meunList">
          <router-link
            :to="`/OnlinePrinting/DisplayPoduct/${item.id}`"
            tag="li"
            :key="index"
          >
            <div class="item-content">
              <div class="pic">
                <img class="image" :src="item.taxonomyIcon" />
              </div>
              <div class="name">
                {{ item.taxonomyName }}
              </div>
            </div>
          </router-link>
        </template>

        <router-link to="/OnlinePrinting/TakeOrder" tag="li">
          <div class="item-content">
            <div class="pic">
              <img class="image" src="@/assets/img/shoppingcart.png" />
            </div>
            <div class="name">帮我下单</div>
          </div>
        </router-link>
      </ul>
    </div>
    <div class="main">
      <!-- <display-poduct></display-poduct> -->
      <!-- <take-order></take-order> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import NevMenuHome from '@/components/NevMenuHome.vue'
import { selectAllTaxonomy } from '../../api/printonline'
export default {
  data () {
    return {
      meunList: []
    }
  },
  mounted () {
    this.init()
  },
  components: {
    NevMenuHome
  },
  methods: {
    async init () {
      const res = await selectAllTaxonomy({ id: 39 })
      this.meunList = res.data[0].childList
      if (!this.$route.params.id && this.$route.name != 'DispalyAll') {
        this.$router.push({ path: `/OnlinePrinting/DisplayPoduct/${this.meunList[0].id}` })
      }
    }

  },
  watch: {
    $route: {
      handler (val, oldval) {
        console.log(val)
        if (!val.params.id && val.name == 'OnlinePrinting') {
          this.init()
        }
      },
      // 深度观察监听
      deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
.nev-menu-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .router-link-active {
    background-color: #e7e7ef;
    position: relative;
  }
  .router-link-active::after {
    content: " ";
    display: block;
    width: 5px;
    height: 30px;
    background: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  li {
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;

    .item-content {
      width: 218px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      .pic {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        display: inline-block;
        position: relative;
        .image {
          position: absolute;
          top: 0;
          width: 14px;
          height: 14px;
        }
      }
      .name {
        width: 60%;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #33383e;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.content {
  width: 100%;

  margin: auto;
  position: absolute;
  top: 80px;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.699);
  .aside {
    width: 250px;
    height: 100%;
    padding-top: 15px;
    float: left;
    background-color: white;
    position: relative;
    .space {
      height: 1px;
      background: rgb(218, 218, 218);
      width: 166px;

      margin: 30px 0 30px 30px;
    }
  }
  .main {
    // float: right;
    display: inline-block;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 260px;
    background-color: white;
    padding-top: 30px;
    padding-left: 50px;
  }
}
</style>
