var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "displayPoduct" },
    [
      !_vm.refresh
        ? _c("classification", {
            attrs: { title: "分类", dataList: _vm.classList },
            on: { change: _vm.changeClass }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "product-area" },
        _vm._l(_vm.imgShow, function(itemList, index) {
          return _c(
            "div",
            { key: index, staticClass: "product-list" },
            _vm._l(itemList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "product-list-item",
                  on: {
                    click: function($event) {
                      return _vm.PoductDetail(item)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "http://" + item.describe.picture[0] }
                  }),
                  _c(
                    "div",
                    { staticClass: "name", attrs: { align: "center" } },
                    [_vm._v(_vm._s(item.spuName))]
                  )
                ]
              )
            }),
            0
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticStyle: { margin: "20px 0px 20px 20px" },
          attrs: { align: "right" }
        },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }