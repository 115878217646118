var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "take-order-area" }, [
    _c("div", { staticClass: "title" }, [_vm._v("帮我下单")]),
    _vm._m(0),
    _c("div", { staticClass: "user-area" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "name required" }, [_vm._v("货品名称")]),
        _c(
          "div",
          { staticClass: "input-large" },
          [
            _c("el-input", {
              attrs: { maxlength: "100", "show-word-limit": "" },
              model: {
                value: _vm.goodsName,
                callback: function($$v) {
                  _vm.goodsName = $$v
                },
                expression: "goodsName"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "name required" }, [_vm._v("联系人")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              attrs: { maxlength: "20", "show-word-limit": "" },
              model: {
                value: _vm.founder,
                callback: function($$v) {
                  _vm.founder = $$v
                },
                expression: "founder"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "name required" }, [_vm._v("手机号码")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              directives: [{ name: "Int", rawName: "v-Int" }],
              model: {
                value: _vm.founderPhone,
                callback: function($$v) {
                  _vm.founderPhone = $$v
                },
                expression: "founderPhone"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "name" }, [_vm._v("QQ号码")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              directives: [{ name: "Int", rawName: "v-Int" }],
              attrs: { maxlength: "20", "show-word-limit": "" },
              model: {
                value: _vm.qqNumber,
                callback: function($$v) {
                  _vm.qqNumber = $$v
                },
                expression: "qqNumber"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "remark-area" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "name" }, [_vm._v("需求描述")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                placeholder: "请输入内容",
                "show-word-limit": "",
                maxlength: "500",
                resize: "none",
                rows: 5
              },
              model: {
                value: _vm.describe,
                callback: function($$v) {
                  _vm.describe = $$v
                },
                expression: "describe"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "name" }, [_vm._v("文件")]),
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "upload-area" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.uploadUrl,
                    "before-upload": _vm.awardBeforeUpload,
                    "on-success": _vm.uploadAwardSuccess,
                    "on-remove": _vm.onRemove,
                    "on-progress": _vm.onProgress,
                    limit: 1,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c("div", { staticClass: "upload" }, [
                    _vm._v(_vm._s(_vm.tips))
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("文件大小不超过100M")]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "name" }, [_vm._v("备注")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("el-input", {
              attrs: { "show-word-limit": "", maxlength: "100" },
              model: {
                value: _vm.remark,
                callback: function($$v) {
                  _vm.remark = $$v
                },
                expression: "remark"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "submit-area" }, [
      _c("div", { staticClass: "submit-button", on: { click: _vm.submit } }, [
        _vm._v("立即提交")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-list" }, [
      _c("ul", { staticClass: "step-ul" }, [
        _c("li", { staticClass: "step-item" }, [_vm._v("提交下单需求")]),
        _c("li", { staticClass: "step-item" }, [_vm._v("工作人员处理文件")]),
        _c("li", { staticClass: "step-item" }, [_vm._v("确认工艺参数")]),
        _c("li", { staticClass: "step-item" }, [_vm._v("下单支付")]),
        _c("li", { staticClass: "step-item" }, [_vm._v("确定生产")]),
        _c("li", { staticClass: "step-item" }, [_vm._v("等待收货")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }